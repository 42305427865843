  .form-signin {
    max-width: 375px;
    padding: 15px;
    margin: 0 auto;
    padding-bottom: 40px;
    background-color: #eee;
    height: 425px;
    border-radius: 6px;
  }
  
  .form-signin-header {
    margin-bottom: 10px;
  }
  
  .form-signin input[type="text"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .nav-link a{
    color: rgba(255,255,255, 0.5)
  }

  .nav-link:hover a{
    color: rgba(255,255,255, 0.8)
  }

  .react-grid-layout {
    background: #eee;
  }
  
  .columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
  }

  .react-grid-item {
    box-sizing: border-box;
  }
  
  .react-grid-item:not(.react-grid-placeholder) {
    background: white;
    border: 1px solid #434;
    border-radius: 8px;
  }
  .react-grid-item.resizing {
    opacity: 0.9;
  }
  .react-grid-item.static {
    background: #cce;
  }
  .react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24px;
  }
  .react-grid-item .minMax {
    font-size: 12px;
  }
  .react-grid-item .add {
    cursor: pointer;
  }
  .react-grid-dragHandleExample{
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  
